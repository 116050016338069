@import '@appkit4/styles/appkit.min.css';
@import '@appkit4/react-components/dist/styles/appkit4-react.min.css';

.body-xs {
  font-size: 12px !important;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.6px;
}

.body-sm {
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 400;
}

.body-md {
  font-size: 16px !important;
  line-height: 24px;
  font-weight: 400;
}

.heading-sm {
  font-size: 20px !important;
  line-height: 24px;
  font-weight: 500;
}

.heading-md {
  font-size: 24px !important;
  line-height: 32px;
  font-weight: 500;
}

.heading-lg {
  font-size: 36px !important;
  line-height: 42px;
  font-weight: 500;
}

.heading-xl {
  font-size: 48px !important;
  line-height: 48px;
  font-weight: 500;
}

.bedge-base {
  background-color: rgba(65, 83, 133, 0.12);
  color: #415385;
  box-shadow: 0 0 0 0.0625rem #415385;
  border-radius: 9999px;
}

.pannel-base {
  box-shadow: 0 0.125rem 0.25rem -0.125rem rgba(71, 71, 71, 0.24) !important;
  border-radius: 0.5rem !important;
}

.ap-filter-button::before {
  content: '\e874';
  width: 14px;
  height: 14px;
  margin-right: 10px;
  color: #415385;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  text-decoration: inherit;
  font-family: 'appkit4-font';
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  font-feature-settings: normal;
  font-variant: normal;
  letter-spacing: normal;
}

.ap-filter-button .ap-filter-button-title {
  width: 80%;
  text-align: left !important;
  color: #415385;
}

.border-base {
  box-shadow: inset 0 0 0 0.0625rem #d1d1d1;
  border-radius: 0.5rem;
}

.input-border {
  border-radius: 0.25rem !important;
  box-shadow: inset 0 0 0 0.0625rem #d1d1d1 !important;
}

.ck ol {
  list-style: auto;
  margin-left: 1.2rem;
}

.ck ul {
  list-style: disc;
  margin-left: 1.2rem;
}

.ck a {
  color: #415385 !important;
  text-decoration: underline;
}

.ap-text-editor-container {
  border-radius: 0.25rem !important;
  box-shadow: inset 0 0 0 0.0625rem #d1d1d1 !important;
  position: relative;
}

.ck-editor .ck.ck-editor__editable_inline {
  background: none !important;
  min-height: 500px;
  max-height: 50vh;
}

.ck.ck-toolbar {
  background: none !important;
}

.ck-editor .ck-toolbar {
  background: none !important;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.ap-fileupload-content {
  border: dashed 0.115rem #d1d1d1 !important;
}

.grid-row-none {
  grid-row: none;
}

.ap-fileupload-footer {
  display: none !important;
}

.user-info-card {
  min-width: 300px;
  max-width: fit-content;
  min-height: 120px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.user-info-card button {
  display: block;
  margin-left: auto;
  margin-top: 10px;
  padding: 6px 10px;
  font-size: 12px;
  background-color: #415385;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-info-card button:hover {
  background-color: #2c3e50;
}
